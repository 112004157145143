import "@fontsource/open-sans/variable.css";
import "../style/index.css";
import "../style/styles.css";
import Layout from "../components/Layout";
import wrapper from "../redux/store";
import { withRouter, useRouter } from "next/router";
import Clicks from "../components/analytics/Clicks";
import React, { useEffect } from "react";
import TagManager from 'react-gtm-module';
import { DefaultSeo } from "next-seo";
const tagManagerArgs = {
  gtmId: 'GTM-MTNSHNW'
}

function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    Clicks(router);
  }, [router]);
  return (
    <Layout>
      <DefaultSeo titleTemplate='%s | Trademark-Genius' defaultTitle = 'Trademark-Genius' />
      <Component {...pageProps} />
    </Layout>
  );
}
export default wrapper.withRedux(withRouter(App));;