export const ROUTES = {
  search: '/search',
  searchOnline: '/search-online',
  search2: '/search-2',
  trademarkRegistration: '/trademark-registration',
  trademarkRegistrationFiling: '/registration-trademark',
  comprehensiveSearch: '/comprehensive-trademark-search',
  comprehensiveSearchFiling: '/comprehensive-search-report',
  trademarkMonitoring: '/trademark-monitoring',
  trademarkMonitoringFiling: 'trademark-monitoring-report',
  officeAction: '/office-action',
  fillingRenewal: '/filling-renewal',
};
