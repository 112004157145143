import { config } from "_config";
import { getApiProxyUrl } from "_helpers/proxy";
import { AxiosClient } from "services/AxiosClient";

const { trademarkApiUrl } = config;

class TrademarkApiService extends AxiosClient {
  constructor(url) {
    super(url);
    this.search = this.search.bind(this);
  }

  search({ ...params } = {}) {
    const path = "/trademarks/search";
    return this.client.get(path, { params });
  }
}

const ServiceInstance = new TrademarkApiService(trademarkApiUrl);
const ProxyInstance = new TrademarkApiService(getApiProxyUrl());

export { ServiceInstance as TrademarkApiService, ProxyInstance as TrademarkApiProxy };
