import React, { useLayoutEffect, useState, useEffect } from "react";
import { Divider } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Link from "next/link";
import OurServiceMenu from "./OurServiceMenu";
import PhoneIcon from '@mui/icons-material/Phone';
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

  function MobilNavMui({ router }) {
  const size = useWindowSize();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const membershipLink = process.env.NEXT_PUBLIC_MEMBERSHIP_LINK;
  
  useEffect(() => {
    if (size.width > 800) {
      setShow(false);
    }
  }, [size]);
    let phone = process.env.NEXT_PUBLIC_PHONE_APP;
  return (
    <>
      <img
        src={`${publicRuntimeConfig.basePath}/close-icon.svg`}
        alt=""
        style={{ display: show ? "initial" : "none" }}
        className="icon"
        onClick={handleClose}
        layout="fill"
      />

      <a href={"tel:"+phone}><PhoneIcon style={{ display: show ? "none" : "initial", color:"#7B848D", marginRight: "10px" }}
                         className="icon"
                         layout="fill"/></a>

      <img
        src={`${publicRuntimeConfig.basePath}/hamburger-icon.svg`}
        alt=""
        style={{ display: show ? "none" : "initial" }}
        onClick={handleShow}
        className="icon"
        layout="fill"
      />

      <Drawer anchor="left" open={show} onClose={handleClose} hideBackdrop>
        <OurServiceMenu handleClose={handleClose} />

        {/* <Link href="/OurServices">
          <a onClick={handleClose} className={router.pathname.includes("OurServices") ? "active" : ""}>
            Our Services
          </a>
        </Link> */}
        {/*<br />*/}
        {/*<Link href="/LearningCenter">*/}
        {/*  <a onClick={handleClose} className={router.pathname === "/LearningCenter" ? "active" : ""}>*/}
        {/*    Learning Center*/}
        {/*  </a>*/}
        {/*</Link>*/}
        <br />
        <Link href="/about">
          <a onClick={handleClose} className={router.pathname === "/about" ? "active" : ""}>
            About Us
          </a>
        </Link>
        <br />
        <Link href="/faq">
          <a onClick={handleClose} className={router.pathname === "/faq" ? "active" : ""}>
            FAQs
          </a>
        </Link>
        <br />
        <Link href="/guarantee">
          <a onClick={handleClose} className={router.pathname === "/guarantee" ? "active" : ""}>
            Our Guarantee
          </a>
        </Link>
        <br />
        <Divider style={{ background: "#ECEDF1" }} />
        <br />
        <Link href="/guarantee">
          <a onClick={handleClose} className={"our-guarantee"}>
            Our Guarantee
          </a>
        </Link>
        <Link href={membershipLink}>
          <a onClick={handleClose} className={"my-account-btn"}>
            My Account
          </a>
        </Link>
      </Drawer>
    </>
  );
}

export default MobilNavMui;
