import { combineReducers } from "redux";
import productReducer from "./productsReducer";
import orderReducer from "./orderReducer";
import searchReducer from "./searchReducer";
import searchSlice from "./search/slice";

const rootReducer = combineReducers({
  products: productReducer,
  order: orderReducer,
  search: searchReducer,
  search2: searchSlice,
});

export default rootReducer;
