import { getUA, isMobile } from 'react-device-detect';
import { config } from '_config';
const { apiUrl } = config;

export default function Clicks(location) {
  if (location.query.gclid) {
    try {
      const gclid = location.query.gclid;
      let matcher = /[a-zA-Z0-9 -_&.\\/]+/gi;
      let matches = gclid.match(matcher);
      localStorage.setItem('gclid', matches.join(''));
    } catch (error) {}
  }
  if (location.query.source) {
    try {
      const source = location.query.source;
      let matcher = /[a-zA-Z0-9 -_&.\\/]+/gi;
      let matches = source.match(matcher);
      localStorage.setItem('source', matches.join(''));
    } catch (error) {}
  }
  if (location.query.keyword) {
    try {
      const keyword = location.query.keyword;
      let matcher = /[a-zA-Z0-9 -_&.\\/]+/gi;
      let matches = keyword.match(matcher);
      localStorage.setItem('keyword', matches.join(''));
    } catch (error) {}
  }
  if (location.query.adAccount) {
    try {
      const adAccount = location.query.adAccount;
      let matcher = /[a-zA-Z0-9 -_&.\\/]+/gi;
      let matches = adAccount.match(matcher);
      localStorage.setItem('adAccount', matches.join(''));
    } catch (error) {}
  }

  let pageType = 'presellPage';
  let click = {};
  click.requestUri = window.location.href;
  if (
    location.query.adAccount ||
    location.query.keyword ||
    location.query.source ||
    location.query.gclid
  ) {
    try {
      localStorage.setItem('requestUri', window.location.href);
    } catch (error) {}
  }
  if (
    localStorage &&
    localStorage.getItem('requestUri') &&
    localStorage.getItem('requestUri').includes(window.location.href)
  ) {
    click.requestUri = localStorage.getItem('requestUri');
  }
  click.device = isMobile && !getUA.includes('Intel') ? 'MOBILE' : 'DESKTOP';
  if (
    location.pathname.includes('registration-trademark') ||
    location.pathname.includes('comprehensive-search-report') ||
    location.pathname.includes('statement-of-use-report')
  )
    pageType = 'leadPage';
  else if (location.pathname.includes('payment')) pageType = 'checkoutPage';
  else if (location.pathname.includes('thankyou')) pageType = 'thankyouPage';
  else if (location.pathname.includes('upsell')) pageType = 'upsellPage1';
  click.pageType = pageType;
  if (
    localStorage &&
    localStorage.getItem('crmSessionId') &&
    pageType !== 'presellPage'
  ) {
    click.crmSessionId = localStorage.getItem('crmSessionId');
  }

  let salePage = window.location.href;
  let salePageURLArr = salePage.split('/');
  let salePageURL = (salePageURLArr[0] + '//' + salePageURLArr[2]).replace(
    /\/+$/,
    ''
  );
  let url = apiUrl + '/clicks';
  let fallbackUrl = `${salePageURL}/crm` + '/clicks';

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(click),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      if (res.crmSessionId && pageType !== 'thankyouPage') {
        try {
          localStorage.setItem('crmSessionId', res.crmSessionId);
        } catch (error) {}
      }
      if (pageType === 'thankyouPage') {
        if (localStorage && localStorage.getItem('crmSessionId')) {
          localStorage.removeItem('crmSessionId');
        }
      }
    })
    .catch((error) => {
      if (error.request) {
        fetch(fallbackUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(click),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.error) {
              throw res.error;
            }
            if (res.crmSessionId && pageType !== 'thankyouPage') {
              try {
                localStorage.setItem('crmSessionId', res.crmSessionId);
              } catch (error) {}
            }
            if (pageType === 'thankyouPage') {
              if (localStorage && localStorage.getItem('crmSessionId')) {
                localStorage.removeItem('crmSessionId');
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log(error);
      }
    });
}
