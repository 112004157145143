const initialState = {
    loading:false,
    search: null,
    error: null,
}
// eslint-disable-next-line
export default function(state = initialState, action){
    switch(action.type){
        case "SEARCH_STARTED":
            return {
                ...state,
                loading: true,
                error: null
            }
        case "SEARCH_SUCCESS":
            return {
                ...state,
                search: action.search,
                loading: false,
                error: null
            }
        case "SEARCH_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case "CLEAR_ERROR": {
            return {
                ...state,
                loading: false,
                error: null
            };
        }
        default: return state
    }

}