import React from 'react';
import MobilNavMui from './MobilNavMui';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Navbar, Nav } from 'react-bootstrap';
import OurServiceMenu from './OurServiceMenu';
import NumberFormat from 'react-number-format';
import PhoneIcon from '@mui/icons-material/Phone';
import { ROUTES } from '_constants/routes';
import { clearSessionValue } from '../_helpers/storage';

const { publicRuntimeConfig } = getConfig();

const Header = () => {
  let phone = process.env.NEXT_PUBLIC_PHONE_APP;
  const router = useRouter();
  const membershipLink = process.env.NEXT_PUBLIC_MEMBERSHIP_LINK;
  const handleClear = () => {
    localStorage.removeItem('path');
    localStorage.removeItem('product');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenDiscount');
    localStorage.removeItem('updated');
    clearSessionValue('orderParams');
    if (router.pathname === ROUTES.trademarkRegistrationFiling) router.reload();
  };
  return (
    <div className="header">
      <div className="logo">
        <Link href="/">
          <a>
            <img
              src={`${publicRuntimeConfig.basePath}/logo.svg`}
              alt=""
              layout="fill"
            />
          </a>
        </Link>
      </div>
      <div className="topnav">
        <Navbar collapseOnSelect expand="sm">
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <OurServiceMenu />

              {/*<Link href={`/#`}>*/}
              {/*  <a>Learning Center</a>*/}
              {/*</Link>*/}

              <Link href={`/about`}>
                <a
                  className={router.pathname.includes('about') ? 'active' : ''}
                >
                  About Us
                </a>
              </Link>

              <Link href={`/faq`}>
                <a className={router.pathname.includes('faq') ? 'active' : ''}>
                  FAQs
                </a>
              </Link>

              <Link href={`/guarantee`}>
                <a
                  className={
                    router.pathname.includes('guarantee') ? 'active' : ''
                  }
                >
                  Our Guarantee
                </a>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="my-account-btn">
        <a href={membershipLink}>My Account</a>
      </div>
      <div className="top-header-phone">
        <a href={'tel:' + phone}>
          <PhoneIcon />
          <NumberFormat
            style={{ marginLeft: '5px' }}
            value={phone.substring(2)}
            displayType={'text'}
            format="(###) ### - ####"
          />
        </a>
      </div>
      <div className="free-search-btn">
        <Link href={ROUTES.trademarkRegistrationFiling}>
          <a onClick={handleClear}>Secure My Trademark</a>
        </Link>
      </div>

      <div className="mobile-menu">
        <MobilNavMui router={router} />
      </div>
    </div>
  );
};

export default Header;
