import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { TrademarkApiService } from 'services';
import { processError, processResponse } from '_helpers/api';

export const startSearch = createAsyncThunk(
  'search2/startSearch',
  async function (searchParams, { rejectWithValue }) {
    try {
      const { name } = searchParams || {};

      if (name?.trim()?.length < 1) {
        throw new Error('You should enter at least 1 symbol');
      }

      const response = await TrademarkApiService.search({ ...searchParams });
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const searchSlice = createSlice({
  name: 'search2',
  initialState: {
    search: '',
    page: '1',
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    refreshSearch(state, action) {
      state.search = action.payload;
    },
    refreshPage(state, action) {
      state.page = action.payload;
    },
    resetSearch(state) {
      state.search = '';
      state.page = '1';
      state.data = {};
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startSearch.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(startSearch.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(startSearch.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});
export const { refreshSearch, refreshPage, resetSearch } = searchSlice.actions;
export default searchSlice.reducer;
