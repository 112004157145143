import { config } from '_config';
import { AxiosClient } from 'services/AxiosClient';

const { trademarkApiUrl } = config;

class USPTOCategoryService extends AxiosClient {
  constructor(url) {
    super(url);
    this.getList = this.getList.bind(this);
  }

  getList({ termId, search, description, isGoods, page, count }) {
    const path = '/trademarks/categories';
    return new Promise((resolve) => {
      this.client
        .get(path, {
          params: { termId, search, description, isGoods, page, count },
        })
        .then((response) => {
          const data = response?.data ? { ...response?.data } : {};
          resolve({ ...data });
        })
        .catch((e) => {
          resolve({ elements: [], maxPerPage: count, totalResults: 0 });
        });
    });
  }
}

const ServiceInstance = new USPTOCategoryService(trademarkApiUrl);

export { ServiceInstance as USPTOCategoryService };
