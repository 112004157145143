import React from 'react';
import Link from 'next/link';
import { NavDropdown } from 'react-bootstrap';

const menuItems = [
  {
    title: 'Trademark Registration',
    href: '/trademark-registration',
  },
  {
    title: 'Comprehensive Trademark Search',
    href: '/comprehensive-trademark-search',
  },
  {
    title: 'Free Trademark Search',
    href: '/search',
  },
  {
    title: 'Statement of Use',
    href: '/statement-of-use',
  },
  {
    title: 'Trademark Monitoring',
    href: '/trademark-monitoring',
  },
  {
    title: 'Office Action Response',
    href: '/office-action',
  },
  {
    title: 'Filling a Renewal',
    href: '/filling-renewal',
  },
  {
    title: 'Request for Extension',
    href: '/extension',
  },
  {
    title: 'Revival From Abandonment',
    href: '/petition-to-revive',
  },
  {
    title: 'Logo Design Service',
    href: '/logo-design-service',
  },
];

const OurServiceMenu = ({ handleClose }) => {
  return (
    <>
      <NavDropdown
        title="Our Services"
        id="collasible-nav-dropdown"
        className="secondary-miu-menu"
      >
        {menuItems.map((item, i) => (
          <Link href={item.href} passHref key={i}>
            <NavDropdown.Item onClick={handleClose}>
              {item.title}
            </NavDropdown.Item>
          </Link>
        ))}
      </NavDropdown>
    </>
  );
};

export default OurServiceMenu;
